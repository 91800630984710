// We need to include ie11 polyfills used by webpack dynamic import
// because webpack generated code does not go through babel
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

import 'intersection-observer';
import 'vanilla-cookieconsent';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import gmapsInit from './utils/gmaps.js';
import { mapStyles } from './utils/gmapsSettings.js';

Alpine.plugin(collapse);

window.Alpine = Alpine;

Alpine.start();

async function initMaps() {
    try {
        const google = await gmapsInit(
            'AIzaSyBesgoR-RxhnpVOaCmyYWBiMCRRl7wJKqc',
        );
        const myLatLng = { lat: 53.55202735442689, lng: 9.961887327326398 };
        // eslint-disable-next-line no-unused-vars
        const map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(
                53.55202735442689,
                9.961887327326398,
            ),
            maxZoom: 24,
            minZoom: 1,
            zoom: 15,
            disableDefaultUI: true,
            zoomControl: false,
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: mapStyles,
        });
        const marker = new google.maps.Marker({
            position: myLatLng,
            map,
            label: null,
            icon: {
                labelOrigin: new google.maps.Point(0, 0),
                scaledSize: new google.maps.Size(50, 50), // scaled size
                url: '/dist/img/gmap/pin.svg',
            },
        });
        // eslint-disable-next-line func-names
        google.maps.event.addListener(marker, 'click', function () {
            window.open('https://goo.gl/maps/PpneuWyFSsci7C8h7', '_blank');
        });
    } catch (error) {
        console.error(error);
    }
}

// Burger menus
document.addEventListener('DOMContentLoaded', function () {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function () {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (var i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function () {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (var i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function () {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
});

/**
 * Bugglyfill mobile safari navbar-hacks
 */
const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
require('viewport-units-buggyfill').init({
    hacks,
});

// App main
const main = async () => {
    // Async load LazySizes and it's plugins
    const LazySizes = await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/respimg/ls.respimg.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/parent-fit/ls.parent-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/object-fit/ls.object-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js'
    );
    // fix issue when image is already in viewport and content is not loaded yet
    document.addEventListener('DOMContentLoaded', function () {
        LazySizes.init();
    });
};

// Execute async function
main().then(() => {
    initMaps();
    const anime = document.querySelector('.js-animate');
    if (anime) {
        import(/* webpackChunkName: "animeJS" */ './modules/anime.js');
    }
    const player = document.querySelector('.js-player');
    if (player) {
        import(/* webpackChunkName: "animeJS" */ './modules/plyr.js');
    }
    const headroom = document.querySelector('.js-nav');
    if (headroom) {
        import(/* webpackChunkName: "headroom" */ './modules/headroom')
            .then(module => module.default.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    const cc = initCookieConsent();

    cc.run({
        autorun: true,
        autoclear_cookies: true,
        page_scripts: true,
        mode: 'opt-in',
        auto_language: 'document',
        gui_options: {
            consent_modal: {
                layout: 'cloud',
                position: 'bottom center',
                transition: 'slide',
                swap_buttons: false,
            },
            settings_modal: {
                layout: 'box',
                transition: 'slide',
            },
        },

        onFirstAction(user_preferences, cookie) {},
        onAccept(cookie) {},
        onChange(cookie, changed_categories) {},

        languages: {
            de: {
                consent_modal: {
                    title: 'Datenschutzeinstellungen',
                    description:
                        'Wir benötigen Ihre Zustimmung, bevor Sie unsere Website weiter besuchen können. Auch wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a class="cc-link" href="/impressum" target="_blank" rel="noopener">Datenschutzerklärung.</a>Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen. <button class="cc-link" type="button" data-cc="c-settings">Datenschutzeinstellungen</button>',
                    primary_btn: {
                        text: 'Notwendige akzeptieren',
                        role: 'accept_necessary',
                    },
                    secondary_btn: {
                        text: 'Alle akzeptieren',
                        role: 'accept_all',
                    },
                },
                settings_modal: {
                    title: 'Einstellungen',
                    save_settings_btn: 'Einstellungen speichern',
                    accept_all_btn: 'Alle akzeptieren',
                    reject_all_btn: 'Alle ablehnen',
                    close_btn_label: 'Schließen',
                    cookie_table_headers: [
                        { col1: 'Name' },
                        { col2: 'Domain' },
                        { col2: 'Beschreibung' },
                    ],
                    blocks: [
                        {
                            title: 'Essenziell',
                            description:
                                'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true,
                            },
                        },
                        {
                            title: 'Statistiken',
                            description:
                                'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false,
                            },
                            cookie_table: [
                                {
                                    col1: '^_ga',
                                    col2: 'www.peachr.de',
                                    col3: 'Google Analytics',
                                    is_regex: true,
                                },
                                {
                                    col1: '_gid',
                                    col2: 'www.peachr.de',
                                    col3: 'Google Analytics',
                                },
                            ],
                        },
                    ],
                },
            },
            en: {
                consent_modal: {
                    title: 'Privacy settings',
                    description:
                    'You must provide us with your consent before you can continue your visit to our website. If you wish to give your consent but are under the age of 16, you must ask your parent or guardian for permission. We use cookies and other technologies on our website. Some of them are essential; others help us to improve this website and your experience on it. Your visit to the website may result in personal data (e.g. IP addresses) being processed, for example for personalized ads and content or for purposes of ad and content measurement. For more information about how we use your data, please see our <a class="cc-link" href="/impressum" target="_blank" rel="noopener">Privacy Policy</a>. You can revoke or adjust your selection at any time under <button class="cc-link" type="button" data-cc="c-settings">Settings.</button>',
                    primary_btn: {
                        text: 'Accept essential',
                        role: 'accept_necessary',
                    },
                    secondary_btn: {
                        text: 'Accept all',
                        role: 'accept_all',
                    },
                },
                settings_modal: {
                    title: 'Settings',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    cookie_table_headers: [
                        { col1: 'Name' },
                        { col2: 'Domain' },
                        { col2: 'Description' },
                    ],
                    blocks: [
                        {
                            title: 'Essential',
                            description:
                                'Essential cookies serve to facilitate basic functions and are necessary for the website to function properly.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true,
                            },
                        },
                        {
                            title: 'Statistics',
                            description:
                                'Statistics cookies collect information anonymously. This information helps us to understand how our visitors use our website.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false,
                            },
                            cookie_table: [
                                {
                                    col1: '^_ga',
                                    col2: 'www.peachr.de',
                                    col3: 'Google Analytics',
                                    is_regex: true,
                                },
                                {
                                    col1: '_gid',
                                    col2: 'www.peachr.de',
                                    col3: 'Google Analytics',
                                },
                            ],
                        },
                    ],
                },
            },
        },
    });

    const btn = document.getElementById('c-s-bn');
    if (btn) {
        btn.addEventListener('click', () => {
            cc.accept('all');
            setTimeout(function () {
                cc.hideSettings();
                cc.hide();
            }, 10);
        });
    }

    /**
     * Slider
     */
    const slider = document.querySelector('.js-slider');
    if (slider) {
        import(/* webpackChunkName: "slider" */ './modules/glide')
            .then(module => module.slider.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }
    /**
     * Carousel
     */
    const carousel = document.querySelector('.js-carousel');
    if (carousel) {
        import(/* webpackChunkName: "carousel" */ './modules/glide')
            .then(module => module.carousel.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }
});

// accept HMR in dev
if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept();
    }
    // eslint-disable-next-line no-unused-expressions
    // import(/* webpackChunkName: "debug" */ '../vue/debug');
}
